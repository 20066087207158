import { newDateUTC } from "./dates";
import { process } from "./reports";

export const DATATYPE_RESDATE = "ResDate";
export const DATATYPE_CHECKIN = "CheckIn";
export const DATATYPE_CHECKOUT = "CheckOut";

export const PRESET_YESTERDAY = "Yesterday";
export const PRESET_TODAY = "Today";
export const PRESET_TOMORROW = "Tomorrow";
export const PRESET_MONTH_TO_DATE = "Month to date";
export const PRESET_YEAR_TO_DATE = "Year to date";
export const PRESET_THIS_MONTH = "This month";
export const PRESET_THIS_YEAR = "This year";
export const PRESET_LAST_7_DAYS = "Last 7 days";
export const PRESET_LAST_30_DAYS = "Last 30 days";
export const PRESET_LAST_FULL_MONTH = "Last month";
export const PRESET_LAST_3_MONTHS = "Last 3 months";
export const PRESET_LAST_6_MONTHS = "Last 6 months";
export const PRESET_LAST_12_MONTHS = "Last 12 months";
export const PRESET_NEXT_7_DAYS = "Next 7 days";
export const PRESET_NEXT_30_DAYS = "Next 30 days";
export const PRESET_NEXT_3_MONTHS = "Next 3 months";
export const PRESET_NEXT_6_MONTHS = "Next 6 months";
export const PRESET_NEXT_12_MONTHS = "Next 12 months";
export const PRESET_CUSTOM = "Custom";
export const PRESET_CHOOSE_MONTH = "Choose month";

export const PRESET_GROUP = [
    PRESET_MONTH_TO_DATE,
    PRESET_YEAR_TO_DATE,
    PRESET_LAST_FULL_MONTH,
    PRESET_CHOOSE_MONTH,
    PRESET_TOMORROW,
    PRESET_YESTERDAY,
    PRESET_LAST_7_DAYS,
    PRESET_LAST_30_DAYS,
    PRESET_LAST_3_MONTHS,
    PRESET_LAST_6_MONTHS,
    PRESET_LAST_12_MONTHS,
    PRESET_NEXT_7_DAYS,
    PRESET_NEXT_30_DAYS,
    PRESET_NEXT_3_MONTHS,
    PRESET_NEXT_6_MONTHS,
    PRESET_NEXT_12_MONTHS,
];

//   ____
//  |  _ \ _ __ ___   ___ ___  ___ ___
//  | |_) | '__/ _ \ / __/ _ \/ __/ __|
//  |  __/| | | (_) | (_|  __/\__ \__ \
//  |_|   |_|  \___/ \___\___||___/___/
export const process_ = ({ js, dataBookings, hotelID }) => {
    let dataToUse = dataBookings.data ?? [];

    // console.log(js.filters.Comparison);

    // drop out dates; and countries, rooms and offers
    const { toDay, fromDay } = { fromDay: js.fromDay, toDay: js.toDay };

    const {
        Countries = [],
        Rooms = [],
        Offers = [],
        Types = [],
        VoucherNames = [],
        Cancellations = "included",
        Comparison = DEFAULT_COMPARISON,
    } = js.filters ?? {};

    let fromDayPrevious = new Date(fromDay);
    let toDayPrevious = new Date(toDay);

    if (Comparison === DEFAULT_COMPARISON) {
        fromDayPrevious.setFullYear(fromDayPrevious.getFullYear() - 1);
        toDayPrevious.setFullYear(toDayPrevious.getFullYear() - 1);
    } else if (Comparison === "previousMonth") {
        fromDayPrevious.setMonth(fromDayPrevious.getMonth() - 1);
        toDayPrevious.setMonth(toDayPrevious.getMonth() - 1);
    }

    fromDayPrevious = formatDate(fromDayPrevious);
    toDayPrevious = formatDate(toDayPrevious);

    // decides if the element must be included according to the Cancellation filter
    const FilterCancellations = (element) =>
        CANCELLATIONS_FILTER[Cancellations].includeCancellation(element);

    const FilterData = (data, from_, to_) => {
        let toPlusOneDay = new Date(to_); //To is included, so we need the following day
        toPlusOneDay.setDate(toPlusOneDay.getDate() + 1);
        let aux = data.filter((e) => {
            let date = new Date(
                helperType(js.dataType, e.ResDate, e.CheckOut, e.CheckIn)
            );

            // console.log(date);

            let filteredCancellation = FilterCancellations(e);

            let isCountry = true;
            let isOffer = true;
            let isRoom = true;
            let isType = true;
            let isVoucherName = true;

            if (Countries.length > 0) {
                const { Country } = e;
                isCountry = Countries.includes(Country);
            }
            if (Offers.length > 0) {
                const { OfferTitle } = e;
                isOffer = Offers.includes(OfferTitle);
            }
            if (Rooms.length > 0) {
                const { RoomName } = e;
                isRoom = Rooms.includes(RoomName);
            }
            if (Types.length > 0) {
                const { Type } = e;
                isType = Types.includes(Type);
            }
            if (VoucherNames.length > 0) {
                const { VoucherName } = e;
                isVoucherName = VoucherNames.includes(VoucherName);
            }

            return (
                new Date(from_) <= date &&
                date < toPlusOneDay &&
                filteredCancellation &&
                isCountry &&
                isOffer &&
                isRoom &&
                isType &&
                isVoucherName
            );
        });

        return aux.map((e) => {
            let date = new Date(
                helperType(js.dataType, e.ResDate, e.CheckOut, e.CheckIn)
            );
            return {
                ...e,
                [GROUP_DATE_DAY]: date.getUTCDate(),
                [GROUP_DATE_MONTH]: (date.getUTCMonth() + 1)
                    .toString()
                    .padStart(2, "0"),
                [GROUP_DATE_YEAR]: date.getUTCFullYear(),
                // [GROUP_DATE_WEEK]: Math.ceil(
                //     ((date - new Date(date.getUTCFullYear(), 0, 1)) /
                //         (1000 * 60 * 60 * 24) + //one day: (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
                //         1) /
                //         7
                // ),
                selectedDate: formatDate(date),
            };
        });
    };

    let dataToUseLastYear = FilterData(
        dataToUse,
        fromDayPrevious,
        toDayPrevious
    );
    dataToUse = FilterData(dataToUse, fromDay, toDay);

    const LENGTH = dataToUse.length;

    // group values by types
    let keys_groups = [...(js.groupBy ?? [])]; // ...timeGroups];

    // console.log(keys_groups);

    // Fix case when no keys because it is voucher type
    dataToUse.forEach((obj) => {
        if (obj.Type === "Voucher") {
            keys_groups.forEach((key) => {
                if (obj[key] === null) {
                    obj[key] = "Voucher";
                }
            });
        }
    });
    dataToUseLastYear.forEach((obj) => {
        if (obj.Type === "Voucher") {
            keys_groups.forEach((key) => {
                if (obj[key] === null) {
                    obj[key] = "Voucher";
                }
            });
        }
    });

    const areKeysTimeBased = keys_groups.every((key) =>
        [GROUP_DATE_MONTH, GROUP_DATE_YEAR, GROUP_DATE_DAY].includes(key)
    );

    // if (Comparison === DEFAULT_COMPARISON) {
    //     fromDayPrevious.setFullYear(fromDayPrevious.getFullYear() - 1);
    //     toDayPrevious.setFullYear(toDayPrevious.getFullYear() - 1);
    // } else if (Comparison === "previousMonth") {
    //     fromDayPrevious.setMonth(fromDayPrevious.getMonth() - 1);
    //     toDayPrevious.setMonth(toDayPrevious.getMonth() - 1);
    // }

    let groups = groupBy(dataToUse, ...keys_groups);
    let groupsLastYear = groupBy(
        dataToUseLastYear.map((e) => {
            let date = new Date(
                e[GROUP_DATE_YEAR],
                Number(e[GROUP_DATE_MONTH]) - 1,
                1
            );

            if (Comparison === DEFAULT_COMPARISON) {
                date.setFullYear(date.getFullYear() + 1);
            } else if (Comparison === "previousMonth") {
                date.setMonth(date.getMonth() + 1);
            }

            let e_ = {
                ...e,
                [GROUP_DATE_YEAR]: date.getFullYear(),
                [GROUP_DATE_MONTH]: (date.getMonth() + 1)
                    .toString()
                    .padStart(2, "0"),
            };

            return e_;
        }),
        ...keys_groups
    );

    // const groupsSet = areKeysTimeBased
    //     ? new Set(Object.keys({ ...groups }))
    //     : new Set(Object.keys({ ...groups, ...groupsLastYear }));
    const groupsSet = new Set(Object.keys({ ...groups, ...groupsLastYear }));

    let entries = Array.from(groupsSet)
        .map((k) => {
            return {
                [k]: {
                    result: groups[k] ? process(groups[k], hotelID) : {},
                    resultOneYearBefore: groupsLastYear[k]
                        ? process(groupsLastYear[k], hotelID)
                        : {},
                    keys: keys_groups.map((key) => [
                        key,
                        groups[k]
                            ? groups[k][0][key]
                            : groupsLastYear[k][0][key],
                    ]),
                    data: [...(groups[k] || []), ...(groupsLastYear[k] || [])],
                },
            };
        })
        .reduce((newObj, v) => {
            return { ...newObj, ...v };
        }, {});

    console.log({ entries, dataBookings });

    return { entries, len: LENGTH };
};

export const formatDate = (date) => date.toISOString().slice(0, 10);

export const getDatesBetween = (startDateStr, endDateStr) => {
    let startDate = new Date(startDateStr);
    let endDate = new Date(endDateStr);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    if (startDate > endDate) {
        [startDate, endDate] = [endDate, startDate];
    }

    const dateArray = [];
    let currentDate = new Date(startDate.getTime());

    while (currentDate <= endDate) {
        dateArray.push(formatDate(currentDate));
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
        currentDate.setHours(0, 0, 0, 0);
    }

    return dateArray;
};

export const PresetDates = (type, aux = {}) => {
    let fromDay = new Date();
    let toDay = new Date();
    // toDay.setUTCDate(toDay.getUTCDate() + 1); // To is included
    const {
        month = new Date().getUTCMonth(),
        year = new Date().getUTCFullYear(),
    } = aux;
    switch (type) {
        case PRESET_CHOOSE_MONTH:
            fromDay = newDateUTC(year, month, 1);
            toDay = newDateUTC(year, month + 1, 0);
            break;
        case PRESET_YESTERDAY:
            fromDay.setUTCDate(fromDay.getUTCDate() - 1);
            toDay.setUTCDate(toDay.getUTCDate() - 1);
            break;
        case PRESET_TODAY:
            break;
        case PRESET_TOMORROW:
            fromDay.setUTCDate(fromDay.getUTCDate() + 1);
            toDay.setUTCDate(toDay.getUTCDate() + 1);
            break;
        case PRESET_THIS_MONTH:
            fromDay.setUTCDate(1);
            toDay = new Date(
                fromDay.getUTCFullYear(),
                fromDay.getUTCMonth() + 1,
                1
            );
            // toDay.setUTCDate(toDay.getUTCDate() + 1); // To is included
            break;
        case PRESET_THIS_YEAR:
            fromDay = new Date(fromDay.getUTCFullYear(), 0, 1);
            toDay = new Date(toDay.getUTCFullYear() + 1, 0, 0);
            // toDay = new Date(toDay.getUTCFullYear() + 1, 0, 1); // To is included
            break;
        case PRESET_MONTH_TO_DATE: // Why -1 day? Because our data calculations do not include current day, so we set it to the previous one
            fromDay.setUTCDate(1);
            // If we are on the first day of the month, we don't subtract 1
            if (
                fromDay.toISOString().slice(0, 10) !==
                toDay.toISOString().slice(0, 10)
            )
                toDay.setUTCDate(toDay.getUTCDate() - 1);
            break;
        case PRESET_YEAR_TO_DATE:
            fromDay = new Date(fromDay.getUTCFullYear(), 0, 1);
            if (fromDay !== toDay) toDay.setUTCDate(toDay.getUTCDate() - 1);
            break;
        case PRESET_LAST_7_DAYS:
            fromDay.setUTCDate(fromDay.getUTCDate() - 7);
            toDay.setUTCDate(toDay.getUTCDate() - 1);
            break;
        case PRESET_LAST_30_DAYS:
            fromDay.setUTCDate(fromDay.getUTCDate() - 30);
            toDay.setUTCDate(toDay.getUTCDate() - 1);
            break;
        case PRESET_LAST_FULL_MONTH:
            fromDay = newDateUTC(year, month - 1, 1);
            toDay = newDateUTC(year, month, 0);
            break;
        case PRESET_LAST_3_MONTHS:
            fromDay.setUTCMonth(fromDay.getUTCMonth() - 3);
            fromDay.setUTCDate(toDay.getUTCDate() - 1);
            toDay.setUTCDate(toDay.getUTCDate() - 1);
            break;
        case PRESET_LAST_6_MONTHS:
            fromDay.setUTCMonth(fromDay.getUTCMonth() - 6);
            fromDay.setUTCDate(toDay.getUTCDate() - 1);
            toDay.setUTCDate(toDay.getUTCDate() - 1);
            break;
        case PRESET_LAST_12_MONTHS:
            fromDay.setFullYear(fromDay.getUTCFullYear() - 1);
            fromDay.setUTCDate(toDay.getUTCDate() - 1);

            toDay.setUTCDate(toDay.getUTCDate() - 1);
            break;
        case PRESET_NEXT_7_DAYS:
            fromDay.setUTCDate(fromDay.getUTCDate() + 1);
            toDay.setUTCDate(toDay.getUTCDate() + 7);
            break;
        case PRESET_NEXT_30_DAYS:
            fromDay.setUTCDate(fromDay.getUTCDate() + 1);
            toDay.setUTCDate(toDay.getUTCDate() + 30);
            break;
        case PRESET_NEXT_3_MONTHS:
            fromDay.setUTCDate(fromDay.getUTCDate() + 1);
            toDay.setUTCDate(fromDay.getUTCDate() + 1);
            toDay.setUTCMonth(fromDay.getUTCMonth() + 3);
            break;
        case PRESET_NEXT_6_MONTHS:
            fromDay.setUTCDate(fromDay.getUTCDate() + 1);
            toDay.setUTCDate(fromDay.getUTCDate() + 1);
            toDay.setUTCMonth(fromDay.getUTCMonth() + 6);
            break;
        case PRESET_NEXT_12_MONTHS:
            fromDay.setUTCDate(fromDay.getUTCDate() + 1);
            toDay.setUTCDate(fromDay.getUTCDate() + 1);
            toDay.setFullYear(fromDay.getUTCFullYear() + 1);
            break;
        default:
            break;
    }

    return { fromDay: formatDate(fromDay), toDay: formatDate(toDay) };
};

export const GROUP_COUNTRIES = "Country";
export const GROUP_OFFERS = "OfferTitle";
export const GROUP_ROOM = "RoomName";
export const GROUP_TYPE = "Type";
export const GROUP_VOUCHERS = "VoucherName";

export const GROUP_DATE_YEAR = "Year";
export const GROUP_DATE_MONTH = "Month";
export const GROUP_DATE_DAY = "Day";
// export const GROUP_DATE_WEEK = "WeekNumber";

export const GROUPs = [
    GROUP_COUNTRIES,
    GROUP_OFFERS,
    GROUP_ROOM,
    GROUP_TYPE,
    GROUP_VOUCHERS,
    GROUP_DATE_YEAR,
    GROUP_DATE_MONTH,
    GROUP_DATE_DAY,
    // GROUP_DATE_WEEK, //TODO: for now this is not in use, remove if we finally are not gonna use it
];

export const helperType = (type, resOption, outOption, inOption) =>
    type === DATATYPE_RESDATE
        ? resOption
        : type === DATATYPE_CHECKOUT
        ? outOption
        : inOption;

export const groupBy = (items, ...keys) => {
    return items.reduce((accumulator, item) => {
        const groupKey = keys.map((key) => item[key]).join("/");

        if (!accumulator[groupKey]) {
            accumulator[groupKey] = [];
        }

        accumulator[groupKey].push(item);

        return accumulator;
    }, {});
};

export const flat = (data) =>
    Object.keys(data)
        .map((ky) => [...Object.values(data[ky])])
        .flat()
        .flat();

export const GB_NotDate = (js) => {
    return (js.groupBy ?? []).filter((e) => {
        return ![
            GROUP_DATE_DAY,
            GROUP_DATE_MONTH,
            // GROUP_DATE_WEEK,
            GROUP_DATE_YEAR,
        ].includes(e);
    });
};

export const DISPLAY_ELEMENT_HELPER = {
    // New
    commissionNotCancelled: {
        // short: "Commission NC",
        short: "Total",
        tooltip: "Commission, for not cancelled",
        upside: false,
        format: { maxDecimals: 2 },
    },
    commissionCancelled: {
        short: "Commission cancelled",
        tooltip: "Commission, for cancelled",
        upside: false,
        format: { maxDecimals: 2 },
    },
    commissionTotal: {
        short: "Commission",
        tooltip: "Commission",
        upside: false,
        format: { maxDecimals: 2 },
    },
    commissionRoomsNotCancelled: {
        // short: "Commission rooms NC",
        short: "Rooms",
        tooltip: "Commission rooms, for not cancelled",
        upside: false,
        format: { maxDecimals: 2 },
    },
    commissionRoomsCancelled: {
        short: "Commission rooms cancelled",
        tooltip: "Commission rooms, for cancelled",
        upside: false,
        format: { maxDecimals: 2 },
    },
    commissionRoomsTotal: {
        short: "Commission rooms",
        tooltip: "Commission rooms",
        upside: false,
        format: { maxDecimals: 2 },
    },

    commissionVouchers: {
        // short: "Commission vouchers",
        short: "Vouchers",
        tooltip: "Commission, for vouchers",
        upside: false,
        format: { maxDecimals: 2 },
    },
    commissionPercentageFromVouchers: {
        short: "Commission % from vouchers",
        tooltip: "Commission %, for vouchers",
        upside: false,
        format: { maxDecimals: 2 },
    },
    commissionPercentageFromRooms: {
        short: "Commission % from rooms",
        tooltip: "Commission %, for rooms",
        upside: false,
        format: { maxDecimals: 2 },
    },

    //
    ABWNotCancelled: {
        short: "ABW NC",
        tooltip: "Average Booking Window, for not cancelled",
        upside: false,
        format: { right: " days", maxDecimals: 2 },
    },
    ABWCancelled: {
        short: "ABW cancelled",
        tooltip: "Average Booking Window, for cancelled bookings",
        upside: true,
        format: { right: " days", maxDecimals: 2 },
    },
    ABWTotal: {
        short: "Avg. booking window (ABW)",
        abbreviation: "ABW",
        tooltip:
            "Average Booking Window Total, for cancelled and not cancelled",
        upside: false,
        format: { right: " days", maxDecimals: 2 },
    },
    // For calculation purposes
    BWTotal: {
        short: "Booking window (BW)",
        abbreviation: "BW",
        tooltip: "Booking Window Total, for cancelled and not cancelled",
        upside: false,
        format: { right: " days", maxDecimals: 2 },
    },
    //
    ALoSNotCancelled: {
        short: "ALoS NC",
        tooltip: "Average Length of Stay, for not cancelled bookings",
        upside: false,
        format: { right: " days", maxDecimals: 2 },
    },
    ALoSCancelled: {
        short: "ALoS cancelled",
        tooltip: "Average Length of Stay, for cancelled bookings",
        upside: false,
        format: { right: " days", maxDecimals: 2 },
    },
    ALoSTotal: {
        short: "Avg. length of stay (ALoS)",
        abbreviation: "ALOS",
        tooltip:
            "Average Length of Stay, for cancelled and not cancelled bookings",
        upside: false,
        format: { right: " days", maxDecimals: 2 },
    },
    ADRNotCancelled: {
        short: "ADR NC",
        tooltip: "Average daily rate, for not cancelled bookings",
        upside: false,
        format: { left: "currency" },
    },
    ADRCancelled: {
        short: "ADR cancelled",
        tooltip: "Average Daily Rate, for cancelled bookings",
        upside: false,
        format: { left: "currency" },
    },
    ADRTotal: {
        short: "Avg. daily rate (ADR)",
        abbreviation: "ADR",
        tooltip: "Average Daily Rate, for cancelled and not cancelled bookings",
        upside: false,
        format: { left: "currency" },
    },
    bookingsNotCancelled: {
        short: "Bookings",
        tooltip: "Number of not cancelled bookings",
        upside: false,
    },
    bookingsCancelled: {
        short: "Cancelled bookings",
        tooltip: "Number of cancelled bookings",
        upside: false,
    },
    bookingsTotal: {
        short: "Bookings (Total)",
        tooltip: "Number of cancelled and not cancelled bookings",
        specific: {
            ArrivalReport: "Arrivals",
        },
        upside: false,
        important: true,
    },
    bookingsRooms: {
        short: "Bookings Rooms",
        specific: {
            FiltersTableReport: "Bookings",
            ArrivalReport: "Arrivals",
        },
        tooltip: "Number of cancelled and not cancelled bookings rooms only",
        upside: false,
    },
    revenueNotCancelled: {
        short: "Revenue NC",
        tooltip: "Amount of not cancelled revenue",
        upside: false,
        format: { left: "currency" },
    },
    revenueCancelled: {
        short: "Revenue cancelled",
        tooltip: "Amount of cancelled revenue",
        upside: false,
        format: { left: "currency" },
    },
    revenueTotal: {
        short: "Total revenue",
        tooltip: "Amount of cancelled and not cancelled revenue",
        upside: false,
        important: true,
        format: { left: "currency" },
        headerFormat: { right: "currency", brackets: true },
    },
    revenueRoom: {
        short: "Room revenue",
        tooltip: "Amount of cancelled or not cancelled room revenue only",
        upside: false,
        format: { left: "currency" },
        headerFormat: { right: "currency", brackets: true },
    },
    revenueExtras: {
        short: "Extras revenue",
        tooltip: "Amount of cancelled or not cancelled revenue extras",
        upside: false,
        format: { left: "currency" },
        headerFormat: { right: "currency", brackets: true },
    },
    revenueRoomAndExtras: {
        short: "Room revenue + extras",
        tooltip:
            "Amount of cancelled or not cancelled for room revenue + extras",
        upside: false,
        format: { left: "currency" },
        headerFormat: { right: "currency", brackets: true },
    },
    revenueVouchers: {
        short: "Vouchers revenue",
        tooltip: "Amount of cancelled or not cancelled revenue vouchers",
        upside: false,
        format: { left: "currency" },
        headerFormat: { right: "currency", brackets: true },
        isVoucher: true,
    },
    roomNightsNotCancelled: {
        short: "Room nights NC",
        tooltip: "Number of not cancelled Room Nights",
        upside: false,
    },
    roomNightsCancelled: {
        short: "Room nights cancelled",
        tooltip: "Number of cancelled Room Nights",
        upside: false,
    },
    roomNightsTotal: {
        short: "Room nights",
        tooltip: "Number of cancelled and not cancelled Room Nights",
        upside: false,
        important: true,
    },
    revenueNettNotCancelled: {
        short: "Revenue nett NC",
        tooltip: "Amount of not cancelled revenue (nett)",
        upside: false,
        format: { left: "currency" },
    },
    revenueNettCancelled: {
        short: "Revenue nett cancelled",
        tooltip: "Amount of cancelled revenue (nett)",
        upside: false,
        format: { left: "currency" },
    },
    revenueNettTotal: {
        short: "Revenue nett",
        tooltip: "Amount of cancelled and not cancelled revenue (nett)",
        upside: false,
        format: { left: "currency" },
    },
    cancellationRate: {
        short: "Cancellation rate",
        tooltip: "Cancellation Rate for the given period and group",
        upside: true,
    },
    vouchersCount: {
        short: "Vouchers sold",
        tooltip:
            "Number of Vouchers in total bought, many vouchers could being purchased in the same booking",
        upside: false,
        isVoucher: true,
    },
    vouchersBookings: {
        short: "Vouchers purchases",
        tooltip:
            "Times that vouchers had being bought, a bought could have multiple vouchers",
        upside: false,
        isVoucher: true,
    },
    voucherMin: {
        short: "Voucher min",
        tooltip: "Voucher's minimum Total Price",
        upside: false,
        format: { left: "currency" },
        isVoucher: true,
    },
    voucherMax: {
        short: "Voucher max",
        tooltip: "Voucher's maximum Total Price",
        upside: false,
        format: { left: "currency" },
        isVoucher: true,
    },
};

export const getMetricName = (metric, place = "default") => {
    const metricInfo = DISPLAY_ELEMENT_HELPER[metric];

    if (!metricInfo) {
        return null;
    }
    if (metricInfo.specific && metricInfo.specific[place]) {
        return metricInfo.specific[place];
    }
    return metricInfo.short;
};

export const CANCELLATIONS_FILTER = {
    included: {
        label: "Included",
        includeCancellation: (element) => true,
        hideKey: "CANCELLATIONS_INCLUDED",
    },
    excluded: {
        label: "Excluded",
        includeCancellation: (element) => !element.CancelStatus,
        hideKey: "CANCELLATIONS_EXCLUDED",
    },
    only: {
        label: "Only",
        includeCancellation: (element) => element.CancelStatus,
        hideKey: "CANCELLATIONS_ONLY",
    },
};

export const COMPARISON_FILTER = {
    previousYear: {
        label: "Previous year",
    },
    previousMonth: {
        label: "Previous month",
    },
};

export const DEFAULT_COMPARISON = "previousYear";
